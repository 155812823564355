import React, { useState } from 'react';

const SquareFootageInputs = () => {
  const [radioButtonSelection, setRadioButtonSelection] = useState('GBA');

  return (
    <>
      <div>Basis of square footage:</div>
      <label htmlFor="GBA" style={{ marginRight: 10 }}>
        <input
          type="radio"
          id="GBA"
          checked={radioButtonSelection === 'GBA'}
          onClick={() => setRadioButtonSelection('GBA')}
          style={{ marginRight: 5 }}
        />
        GBA
      </label>
      <label htmlFor="GLA" style={{ marginRight: 10 }}>
        <input
          type="radio"
          id="GLA"
          checked={radioButtonSelection === 'GLA'}
          onClick={() => setRadioButtonSelection('GLA')}
          style={{ marginRight: 5 }}
        />
        GLA
      </label>
      <label htmlFor="NRA" style={{ marginRight: 10 }}>
        <input
          type="radio"
          id="NRA"
          checked={radioButtonSelection === 'NRA'}
          onClick={() => setRadioButtonSelection('NRA')}
          style={{ marginRight: 5 }}
        />
        NRA
      </label>
      <label htmlFor="NLA" style={{ marginRight: 10 }}>
        <input
          type="radio"
          id="NLA"
          checked={radioButtonSelection === 'NLA'}
          onClick={() => setRadioButtonSelection('NLA')}
          style={{ marginRight: 5 }}
        />
        NLA
      </label>

      <div>
        <span style={{ marginRight: 5, marginBottom: 5 }}>GBA:</span>
        <input />
      </div>
      {radioButtonSelection !== 'GBA' && (
        <div>
          <span style={{ marginRight: 5 }}>{`${radioButtonSelection}`}:</span>
          <input />
        </div>
      )}
    </>
  );
};

export default SquareFootageInputs;
