// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutme-tsx": () => import("./../../../src/pages/aboutme.tsx" /* webpackChunkName: "component---src-pages-aboutme-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-other-things-index-tsx": () => import("./../../../src/pages/other-things/index.tsx" /* webpackChunkName: "component---src-pages-other-things-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx" */),
  "component---src-pages-thoughts-index-tsx": () => import("./../../../src/pages/thoughts/index.tsx" /* webpackChunkName: "component---src-pages-thoughts-index-tsx" */),
  "component---src-pages-thoughts-mdx-frontmatter-slug-tsx": () => import("./../../../src/pages/thoughts/{mdx.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-thoughts-mdx-frontmatter-slug-tsx" */)
}

